<ng-container *ngIf="control">
  <div class="field-checkbox">
    <p-checkbox
      #contentInput
      [formControl]="control"
      [inputId]="controlName"
      [binary]="true"
      [readonly]="readonly"
      (onChange)="onChange($event)"
    ></p-checkbox>
    <label [for]="controlName"> {{ label }}</label>
  </div>
</ng-container>
