import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LocalConfigService {
  salvarConfig(usuarioId: string, config: any) {
    localStorage.setItem(usuarioId, JSON.stringify(config));
    return config;
  }
  buscarConfig(usuarioId: string) {
    return JSON.parse(localStorage.getItem(usuarioId));
  }
}
