import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-multiselect-input',
  templateUrl: './multiselect-input.component.html',
  styleUrls: ['./multiselect-input.component.scss']
})
export class MultiselectInputComponent extends BaseInput {
  @Input() control: FormControl;
  @Input() options: any[];
  @Input() readonly: boolean;
  @Input() placeholder: string = 'Selecione';
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Input() emptyFilterMessage: string = 'Nenhum item encontrado';
  @Input() optionLabel: string = 'texto';
  @Input() optionValue: string = 'valor';
  @Input() showClear: boolean;
  @Input() showFilter: boolean;
  @Input() filterBy: string;

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  get hasErrors() {
    return this.control?.errors && this.control?.touched
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
}
